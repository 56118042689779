function o(t, a) {
  if (t.length < 2)
    throw new Error(`Could not get allocation for ${t}`);
  const r = 1 / t.length * a * 100, n = 1 / t.length * (a + 1) * 100;
  return [r, n];
}
function e(t) {
  const a = {};
  if (!t.includes("A-control"))
    throw Error("No control group found with name 'A-control'");
  for (const [r, n] of t.entries())
    a[n] = {
      allocation: o(t, r)
    };
  return a;
}
const i = {
  // CHECKOUT ------------------------------------------------------------------
  "abtest_dba_3240-dba_1": {
    description: "Improved date picker",
    variants: e(["A-control", "B-new"]),
    domain_and_index: "abtest_name_dba_1",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3297-dba_7": {
    description: "Updated cart tiles retest",
    variants: e(["A-control", "B-price", "C-next"]),
    domain_and_index: "abtest_name_dba_7",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba-3298-dba_3": {
    description: "Free pickup or delivery in cart summary",
    variants: e(["A-control", "B-promo", "C-green"]),
    domain_and_index: "abtest_name_dba_3",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  // MYACCOUNT -----------------------------------------------------------------
  "abtest_drl_3327-drl_1": {
    description: "Passwordless signup as default",
    variants: e(["A-control", "B-default", "C-explanation"]),
    domain_and_index: "abtest_name_drl_1",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  // SHOPFRONT -----------------------------------------------------------------
  "abtest_xtd_autosug-ew_4": {
    description: "Extend autosuggest/autocomplete with categories filter",
    variants: e(["A-control", "B-six-sug", "C-incl-cat"]),
    domain_and_index: "abtest_name_ew_4",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  }
};
export {
  i as experiments
};
